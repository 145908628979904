<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card>
                    <v-card-title class="title">
                        <v-icon>layers</v-icon>
                        Ledger Head Opening Report
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card flat>
                        <v-card-title class="title">
                            <v-flex xs4>
                                <v-text-field
                                        flat
                                        color="primary"
                                        class="pa-0"
                                        label="Search By Name"
                                        v-model="dataSearch"
                                />
                            </v-flex>

                        </v-card-title>
                    </v-card>

                    <v-data-table :headers="headers" :items="form.items.data"
                                  :rows-per-page-items="rowsPerPageItems"
                                  :pagination.sync="pagination"
                                  :total-items="form.items.meta.total"
                                  class="elevation-1">
                        <template slot="items" slot-scope="props">
                            <td>{{ props.index + form.items.meta.from }}</td>
                            <td class="text-xs-left">{{ props.item.name }}</td>
                            <td class="text-xs-left"><span v-if="props.item.drcr_type === 'dr'">{{parseFloat(props.item.amount).numberFormat()}}</span>
                            </td>
                            <td><span
                                    v-if="props.item.drcr_type === 'cr'"> {{parseFloat(props.item.amount).numberFormat()}}</span>
                            </td>

                        </template>
                        <template slot="no-data">
                            Data not found.
                        </template>
                    </v-data-table>

                </v-card>
            </v-flex>
        </v-layout>

    </v-container>
</template>

<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    export default {
        data: () => ({
            form: new Form({
                    name: '',
                    description: '',
                    account_head_id: '',
                    opening_balance: 0,
                    drcr: 'dr',
                },
                '/api/report/opening'
            ),
            aForm: new Form({
                    name: '',
                    description: '',
                    parent_id: '',
                },
                '/api/account-head'
            ),
            searchAH: null,
            isLoading: false,
            rowsPerPageItems: [5, 10, 25, 50, 75, 100],
            pagination: {
                rowsPerPage: 10,
            },
            dataSearch: '',
            aDialog: false,
            search: null,
            searchA: null,
            entries: [],
            entriesA: [],
            accountHeads: [],
            headers: [
                {
                    text: '#',
                    align: 'left',
                    sortable: false,
                    value: 'id'
                },
                {text: 'Name', value: 'name', align: "left"},
                {text: 'Debit Amount', value: 'drcr_type', align: "left"},
                {text: 'Credit Amount', value: 'drcr_type', align: "left"},
            ],
            drcr: [{value: 'dr', text: 'DR'}, {value: 'cr', text: 'CR'}],
            valid: true,
        }),
        mounted: function () {
            this.get();
        },

        computed: {
            ...mapState(['batch']),
            itemsAccountGroup() {
                return this.entries.map(entry => {
                    return Object.assign({}, entry, {Name: entry.name})
                })
            },
            itemsAccount() {
                return this.entriesA.map(entryA => {
                    return {Name: entryA.name, id: entryA.id, name: entryA.name}
                })
            }
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            },
            'dataSearch': function () {
                this.get();
                // console.log('test...');
            },
            search(val) {
                if (!val) return;
                // if (this.isLoading) return;
                this.isLoading = true;
                this.$rest.get('/api/account-head?search=' + val)
                    .then(({data}) => {
                        this.entries = data.data
                    }).catch(err => {
                }).finally(() => (this.isLoading = false))
            },
            searchA(val) {
                if (!val) return;
                // if (this.isLoading) return;
                this.isLoading = true;
                this.$rest.get('/api/account-head?search=' + val)
                    .then(({data}) => {
                        this.entriesA = data.data.map(res => {
                            return {Name: res.name, id: res.id, name: res.name};
                        })
                    }).catch(err => {
                }).finally(() => (this.isLoading = false))
            },
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&search=' + this.dataSearch;
            },

            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                    this.accountHeads = data.data.map(item => ({value: item.id, text: item.name}));
                })

            },

            save() {
                // this.form.account_head_id = this.form.account_head_id.id;
                this.form.store();
            },

            saveAccountGroup() {
                this.aForm.parent_id = this.aForm.parent_id.id;
                this.aForm.store().then(data => {
                    this.getAccountHeads();
                    this.aDialog = false;
                });
            },
            getAccountHeads() {
                this.$rest.get('/api/account-head?rowsPerPage=100').then(({data}) => {
                    this.accountHeads = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                });
            },
            editItem(item) {
                this.entriesA = [];
                this.entriesA.push({
                    Name: item.account_head.name,
                    id: item.account_head.id,
                    name: item.account_head.name
                });
                this.form.account_head_id = item.account_head.id;
                this.form.opening_balance = item.opening.balance;
                this.form.drcr = item.opening.drcr;

            }

        },

    }
</script>
<style lang="scss">
</style>

